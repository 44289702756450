import { ApiClientError } from "@shopware/api-client";
import type { Schemas } from "#shopware";

export const useProductWishlistExtended = (
  product: Ref<Schemas["Product"]>,
) => {
  const { addToWishlist, removeFromWishlist, isInWishlist } =
    useProductWishlist(product.value.id);
  const { pushSuccess, pushError } = useNotifications();
  const { t } = useI18n();

  const toggleWishlistProduct = async () => {
    if (!isInWishlist.value) {
      try {
        await addToWishlist();
        return pushSuccess(
          t(`product.messages.addedToWishlist`, {
            p: product?.value.translated?.name,
          }),
        );
      } catch (error) {
        if (error instanceof ApiClientError) {
          const reason = error.details.errors?.[0]?.detail
            ? `Reason: ${error.details.errors?.[0]?.detail}`
            : "";
          return pushError(
            `${product?.value.translated?.name} cannot be added to wishlist.\n${reason}`,
            {
              timeout: 5000,
            },
          );
        }
      }
    }
    removeFromWishlist();
  };

  return {
    addToWishlist,
    removeFromWishlist,
    isInWishlist,
    toggleWishlistProduct,
  };
};
